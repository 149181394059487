.mac {
    display: flex;
    position: absolute;
    right: 0; /* Ensures it stays on the right side */
    bottom: 0;
    width: 80%; /* Default size for mobile devices */
    opacity: 0; /* Initial state for fade-in effect */
    transform: translateX(100%); /* Initial state for slide-in effect */
    animation: slideFadeUP .5s ease-out forwards; /* Apply animation */
}

/* Media query for larger displays */
@media (min-width: 768px) {
    .mac {
        width: 50%; /* Takes up the right half of the screen on larger displays */
        height: auto; /* Adjusts height according to the content */
        animation: slideFadeIn .5s ease-out forwards; /* Apply animation */
    }
}

/* Keyframes for the slide-in and fade-in effect */
@keyframes slideFadeIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    60% {
        opacity: .5;
        transform: translateX(0);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


@keyframes slideFadeUP {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    60% {
        opacity: .5;
        transform: translateY(0);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}