.quote-container {
    margin: 0;
    padding: 0;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: whitesmoke;
    font-size: 50px;
    text-shadow:
        1px 1px 0 #000,
        -1px 1px 0 #000,
        -1px -1px 0 #000,
        1px -1px 0 #000;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
}

.quote-loading {
    font-size: 1.5rem;
}

